import { graphql } from 'gatsby'
import React from 'react'
import CallFurthur from '../components/CallFurthur'
import Hero from '../components/EquipmentTabs/Hero'
import Footer from '../components/Footer'
import Layout from '../components/Layout'
import Location from '../components/Location'
import NewCustomTabs from '../components/NewCustomTabs'
import SEO from '../components/seo'

const EquipmentTemplate = ({ data }) => {
  const { seo, Equipment: page } = data.wpPage
  const {
    mainBanner: banner,
    overview,
    bestUses,
    advantage,
    equipmentBox,
  } = page
  const bgImage = banner.image.localFile.childImageSharp.fluid
  const advantageBoxes = page.advantageBox
  const equipmentBoxes = page.equipmentBox
  const afterAdvantagesContent = page.afterAdvantagesContent
  console.log(page)
  return (
    <Layout>
      <SEO title={seo.title} description={seo.metaDesc} />
      <Hero type="primary" image={bgImage} />
      <div className="dryvans-container">
        <section className="dryvans tabbedContent equipmentTabs">
          {equipmentBoxes !== null ? (
            <NewCustomTabs
              tabOneTitle={page.mainBanner.title}
              tabOneContent={page.overview}
              equipmentBoxes={equipmentBoxes.map(item => (
                <div className="singleEquipmentBox">
                  <div
                    className="equipmentBoxTitle"
                    dangerouslySetInnerHTML={{ __html: item.equipmentBoxTitle }}
                  ></div>
                  <div
                    className="equipmentServicesList"
                    dangerouslySetInnerHTML={{
                      __html: item.equipmentServicesList,
                    }}
                  ></div>
                </div>
              ))}
              tabTwoTitle=""
              tabTwoContent={page.bestUses}
              tabThreeTitle=""
              tabThreeContent={page.advantage}
              advantageBoxesIcon={
                advantageBoxes !== null
                  ? advantageBoxes.map(
                      item =>
                        item.advantageBoxIcon.localFile.childImageSharp
                          .gatsbyImageData
                    )
                  : null
              }
              advantageBoxesContent={
                advantageBoxes !== null
                  ? advantageBoxes.map(item => item.advantageBoxContent)
                  : null
              }
              afterAdvantagesContent={page.afterAdvantagesContent}
              tabOneLabel="OVERVIEW"
              tabTwoLabel="BEST USES"
              tabThreeLabel="ADVANTAGE"
            />
          ) : (
            <NewCustomTabs
              tabOneTitle={page.mainBanner.title}
              tabOneContent={page.overview}
              equipmentBoxes=""
              tabTwoTitle=""
              tabTwoContent={page.bestUses}
              tabThreeTitle=""
              tabThreeContent={page.advantage}
              advantageBoxesIcon={
                advantageBoxes !== null
                  ? advantageBoxes?.map(
                      item =>
                        item?.advantageBoxIcon?.localFile?.childImageSharp?.gatsbyImageData
                    )
                  : null
              }
              advantageBoxesContent={
                advantageBoxes !== null
                  ? advantageBoxes.map(item => item.advantageBoxContent)
                  : null
              }
              afterAdvantagesContent={page.afterAdvantagesContent}
              tabOneLabel="OVERVIEW"
              tabTwoLabel="BEST USES"
              tabThreeLabel="ADVANTAGE"
            />
          )}
        </section>
      </div>
      <CallFurthur />
      <Location />
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query GetEquipment($id: String) {
    wpPage(id: { eq: $id }) {
      title
      seo {
        title
        metaDesc
      }
      Equipment {
        mainBanner {
          title
          image {
            localFile {
              childImageSharp {
                fluid(quality: 90, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        overview
        bestUses
        advantage
        equipmentBox {
          equipmentBoxTitle
          equipmentServicesList
        }
        advantageBox {
          advantageBoxContent
          advantageBoxIcon {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        afterAdvantagesContent
      }
    }
  }
`

export default EquipmentTemplate
